.MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.MuiAccordionSummary-root {
    padding: 0;
}

div.MuiAccordionDetails-root {
    padding: 0 0 10px;
}

div.MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
}

div.MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}