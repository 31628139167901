.swiper-progress-bar {
    height: 8px;
    background-color: #003399;
    transition: width 0.3s ease-in-out;
    border-radius: 10px;
}

.project-overlay {
    border-radius: 0 0 19px 19px;
    background-color: rgba(252, 251, 255, 0.8);
    color: #232323;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.project-card:hover .project-overlay {
    opacity: 1;
}
