svg.MuiSvgIcon-root {
    width: 2em;
    height: 2em;
    padding: 0;
}

div.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
}

div.MuiListItem-root {
    padding: 0;
}