div.MuiContainer-maxWidthMd {
    max-width: unset;
    padding: 0;
}
div.MuiContainer-root {
    padding: 0;
}

div.MuiCardContent-root {
    padding: 0;
}

div.MuiCardContent-root:last-child {
    padding-bottom: 0;
}